<template>
	<div class="container px-6 mx-auto grid dark:bg-gray-900">
		<loading :active.sync="isLoading"
			:can-cancel="true"
			:on-cancel="onCancel"
			:is-full-page="fullPage">
		</loading>
		<h2
			class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
		>
			Balance General
		</h2>
		<div class="grid md:grid-cols-3 gap-3 mb-4">
			<div class="grid md:grid-cols-2 gap-1">
				
				<div class="text-left">
					AL: <input type="date" v-model="toDate" class="border-2 border-gray-300 rounded">
				</div>
			</div>
			<div class="text-left ">
				Nivel Cta.
				<select v-model="depth_level_inner_accounts" class="border-2 mr-4 border-gray-300 rounded ">
					<option value="3">3</option>
					<option value="4">4</option>
					<!-- <option value="3">4</option> -->
				</select>
			</div>
			<div class="text-left ">
				Tipo de metodo:
				<select v-model="URLSELECT" class="border-2 mr-4 border-gray-300 rounded ">
					<option value="balanceSheetReportCashMethod">Cash</option>
					<option value="balanceSheetReportAccumulationMethod">Acumulativo</option>
					<!-- <option value="3">4</option> -->
				</select>
			</div>
			
		</div>
		
		<div class="text-sm w-full text-left">
			<button @click="processReport()" class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
				Generar
			</button>
		</div>
		<div class="w-full" >
			
			<div id="report" class="w-full p-10" >
				
			</div>
			
		</div>
		
		
	</div>
</template>

<script>

import Axios from 'axios'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import Btn from '@/components/util/btnBasic.vue'
import { jsPDF } from "jspdf"
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import Swal from 'sweetalert2'
// import { mapState } from 'vuex'
	export default {
		name: 'ContentStatusOfProject',
		components: {
			Loading,
			Multiselect,
			Btn,
		},
		props: {
			
		},
		data() {
			return {
				URLSELECT: 'balanceSheetReportCashMethod',
				URL: process.env.VUE_APP_URL_ACC,
				URLAdm: process.env.VUE_APP_URL,
				auxiliary: null,
				ObjAuxiliary:[],
				accountCode:'4.1.1.2.001',
				fromDate:'2022-01-01',
				toDate:'',
				depth_level_inner_accounts:3,
				expense_special_account_code: '1.1.4.1.001',
				income_special_account_code: '2.1.4.1.001',
				isLoading: false,
				fullPage: true,
			}
		},
		computed: {
			...mapState(['user', 'year','access_token'])
		},
		mounted() {
			
		},
		
		methods: {
			onCancel() {
				console.log('User cancelled the loader.')
			},
			addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.transaction.push(tag)
            },
			formatNumber(number, separator = ',', symbol='$', showSymbol = false){
				number = parseFloat(number)
				number = number.toFixed(2)
				if (separator == ',') {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1.$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				} else {
					let montoNuevo = number.toString().replace(/\D/g, "")
					.replace(/([0-9])([0-9]{2})$/, '$1,$2')
					.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
					if (showSymbol) {
						return `${symbol}${montoNuevo}`
					} else {
						return `${montoNuevo}`	
					}
				}
			},
			
			async processReport() {
				this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
				let URL = `${this.URL}${this.URLSELECT}`
				let URLCONFIG = ''
				let paramsConfig = {}
				let	params = {}
				if (this.URLSELECT == 'balanceSheetReportCashMethod') {
					URLCONFIG = `${this.URL}companyConfigurationByName` //busca la configuracion del reporte

					paramsConfig = {
						company: this.user.companyId,
						year: this.year,
						company_id: this.user.companyId,
						module_name: this.URLSELECT, // utilizo URLSELECT para esta propiedad, ya que en este caso, siempre me va a servir ese estring con el nombre de la url
					}

					params = {
						company_id: this.user.companyId,
						fromDate: this.fromDate,
						toDate: this.toDate,
						depth_level_inner_accounts: this.depth_level_inner_accounts,
						company: this.user.companyId,
						year: this.year,
					}
				}else{
					URLCONFIG = `${this.URL}companyConfigurationByName` //busca la configuracion del reporte

					paramsConfig = {
						company: this.user.companyId,
						year: this.year,
						company_id: this.user.companyId,
						module_name: this.URLSELECT, // utilizo URLSELECT para esta propiedad, ya que en este caso, siempre me va a servir ese estring con el nombre de la url
					}
					params = {
						company_id: this.user.companyId,
						fromDate: this.fromDate,
						toDate: this.toDate,
						depth_level_inner_accounts: this.depth_level_inner_accounts,
						expense_special_account_code: this.expense_special_account_code,
						income_special_account_code: this.income_special_account_code,
						company: this.user.companyId,
						year: this.year,
					}
					
				}
				const fetchConfig = {
                    headers: {
                        Accept : 'application/json',
                        "Content-Type": 'application/json'
                        // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                    }
				}

				try {
					let res = await Axios.post(URL, params, fetchConfig)
					let resConfi = await Axios.post(URLCONFIG, paramsConfig, fetchConfig)
					console.log(res)
					// console.log(resConfi.data.data)
					this.crearPDF(res.data.data, resConfi.data.data)
				} catch (error) {
					
				}

				
				this.isLoading = false
			},
			crearPDF(values, config){
				
				let doc = new jsPDF('p', 'pt', 'letter');
				// doc.text(`Page: 1/2` , 549, 20 );
				// console.log(config)
				// return
				let lineaY = 100
				let lineaX = 70

				const COMPONENT = {
                        // fromDate: this.fromDate,
                        toDate: moment(this.toDate).format('MM-DD-YYYY'),
                        // nameAux: nameAux,
                    }

				config.forEach(element => {
					// console.log(element)
					doc.setFontSize(element.size);
					doc.setFont('helvetica',`${element.font_type}`);

					if (element.field_type == "text") {
						doc.text(element.value, element.x1, element.y1);
					}
					if (element.field_type == 'component') {
						const COMP = COMPONENT[element.value]
                        doc.text( COMP, element.x1, element.y1 );
					}
					
				});

				values.accounts.forEach(acc => {
					// console.log(acc)
					doc.setFontSize(7);
					doc.setFont('helvetica',"bold");
					
					doc.text(acc.accountName.toUpperCase() , lineaX, lineaY ); //cuenta
					lineaY += 10
						
					if (acc.accountCode == '3') {
						let income_statement_balance = '';
						if(values.income_statement_balance < 0 ){
							doc.setFont('helvetica',"bold");
							doc.text('OUTCOME OF THE EXERCISE' , lineaX + 25, lineaY ); //TOTAL subcuenta
							lineaY += 10
							doc.setFont('helvetica' ,'normal');
							doc.text('OUTCOME OF THE EXERCISE' , lineaX + 50, lineaY ); //TOTAL subcuenta
							income_statement_balance = values.income_statement_balance.toString()
							doc.text('(' + this.formatNumber(income_statement_balance, ',','$', true) + ')', lineaX + 450, lineaY,'right' )
							lineaY += 10
						}else{
							doc.setFont('helvetica',"bold");
							doc.text('OUTCOME OF THE EXERCISE' , lineaX + 25, lineaY ); //TOTAL subcuenta
							lineaY += 10
							doc.setFont('helvetica' ,'normal');
							doc.text('OUTCOME OF THE EXERCISE' , lineaX + 50, lineaY ); //TOTAL subcuenta
							income_statement_balance = values.income_statement_balance.toString()
							doc.text(this.formatNumber(income_statement_balance, ',','$', true) , lineaX + 450, lineaY ,'right' ); //monto
							lineaY += 10
						}
						// lineaY += 10
					}
					acc.total_accounts.forEach(ele => {
						doc.setFont('helvetica',"bold");
						// console.log(ele.total_account_calculated_balance_at_requested_date)
						// lineaY += 10
						if (ele.total_account_calculated_balance_at_requested_date !=0) {
							doc.text(ele.accountName.toUpperCase() , lineaX + 25, lineaY ); //subcuenta
							// console.log(ele.accountName)
							lineaY += 10

							ele.inner_accounts.forEach(innerAcc => {
								doc.setFont('helvetica' ,'normal');
								if(innerAcc.total_inner_account_calculated_balance_at_requested_date != 0){
									if(innerAcc.total_inner_account_calculated_balance_at_requested_date < 0 ){
										doc.text(innerAcc.accountName.toUpperCase() , lineaX + 50, lineaY ); //inner accounts
										let total_inner_account_calculated_balance_at_requested_date = innerAcc.total_inner_account_calculated_balance_at_requested_date.toString()
										doc.text('(' + this.formatNumber(total_inner_account_calculated_balance_at_requested_date, ',','$', true) + ')', lineaX + 450, lineaY,'right' )
										lineaY += 10
									}else{
										doc.text(innerAcc.accountName.toUpperCase() , lineaX + 50, lineaY ); //inner accounts
										let total_inner_account_calculated_balance_at_requested_date = innerAcc.total_inner_account_calculated_balance_at_requested_date.toString()
										// doc.text(total_inner_account_calculated_balance_at_requested_date, lineaX + 450, lineaY ,'right' ); //monto
										doc.text(this.formatNumber(total_inner_account_calculated_balance_at_requested_date, ',','$', true) , lineaX + 450, lineaY ,'right' ); //monto
										lineaY += 10
									}
									
								}
							});

							
						}
						
						if (ele.total_account_calculated_balance_at_requested_date != 0) {
							// console.log(ele)
							if(ele.total_account_calculated_balance_at_requested_date < 0 ){
								// lineaY += 10
								doc.setFont('helvetica',"bold");
								doc.text('TOTAL ' + ele.accountName.toUpperCase() , lineaX + 25, lineaY ); //TOTAL subcuenta
								let total_account_calculated_balance_at_requested_date = ele.total_account_calculated_balance_at_requested_date.toString()
								doc.line(430, lineaY - 7 , 530, lineaY - 7 ); //horizontal line total
								doc.text('(' + this.formatNumber(total_account_calculated_balance_at_requested_date, ',','$', true) + ')', lineaX + 450, lineaY,'right' )
							}else{
								// lineaY += 10
								doc.setFont('helvetica',"bold");
								doc.text('TOTAL ' + ele.accountName.toUpperCase() , lineaX + 25, lineaY ); //TOTAL subcuenta
								let total_account_calculated_balance_at_requested_date = ele.total_account_calculated_balance_at_requested_date.toString()
								doc.line(430, lineaY - 7 , 530, lineaY - 7 ); //horizontal line total
								doc.text(this.formatNumber(total_account_calculated_balance_at_requested_date, ',','$', true) , lineaX + 450, lineaY ,'right' ); //monto
							}
							lineaY += 10
						}
					});
					
					if (acc.total_root_account_calculated_balance_at_current_date != 0) {
						// console.log(acc)
						if(acc.total_root_account_calculated_balance_at_current_date < 0 ){
							lineaY += 10
							doc.setFont('helvetica',"bold");
							doc.text('TOTAL ' + acc.accountName.toUpperCase() , lineaX, lineaY ); //TOTAL 
							let total_root_account_calculated_balance_at_current_date = acc.total_root_account_calculated_balance_at_current_date.toString()
							doc.line(430, lineaY - 7 , 530, lineaY - 7 ); //horizontal line total
							if (acc.accountGroup === 1) { // usado para del doble subrayado
								doc.line(430, lineaY - 9 , 530, lineaY - 9 ); //horizontal line total
							}
							doc.text('(' + this.formatNumber(total_root_account_calculated_balance_at_current_date, ',','$', true) + ')', lineaX + 450, lineaY,'right' )
						}else{
							lineaY += 10
							doc.setFont('helvetica',"bold");
							doc.text('TOTAL ' + acc.accountName.toUpperCase() , lineaX, lineaY ); //TOTAL 
							let total_root_account_calculated_balance_at_current_date = acc.total_root_account_calculated_balance_at_current_date.toString()
							doc.line(430, lineaY - 7 , 530, lineaY - 7 ); //horizontal line total
							
							if (acc.accountGroup === 1) { // usado para del doble subrayado
								doc.line(430, lineaY + 2 , 530, lineaY + 2); //horizontal line total TOTAL ASSETS
								doc.line(430, lineaY + 4 , 530, lineaY + 4); //horizontal line total TOTAL ASSETS
							}
							
							doc.text(this.formatNumber(total_root_account_calculated_balance_at_current_date, ',','$', true) , lineaX + 450, lineaY ,'right' ); //monto
						}
						lineaY += 10
					}
				});
				lineaY += 10
				doc.setFont('helvetica',"bold");
				doc.text('TOTAL LIABILITIES AND OWNER\'S EQUITY' , lineaX, lineaY ); //TOTAL 
				let acc2_ac3_ib_balance = values.acc2_ac3_ib_balance.toString()
				doc.line(430, lineaY - 9 , 530, lineaY - 9 ); //horizontal line total
				doc.text(this.formatNumber(acc2_ac3_ib_balance, ',','$', true) , lineaX + 450, lineaY ,'right' ); //monto
				doc.line(430, lineaY + 2 , 530, lineaY + 2 ); //horizontal line total
				doc.line(430, lineaY + 4 , 530, lineaY + 4 ); //horizontal line total
				
				// return
				// salida del pdf en blob
				let pdfBlob = doc.output("blob")

				// para tratar el formato blob y pasarlo a base64. Y despues incluir el pdf en in etiqueta iframe
				let elemento = document.getElementById("report");
				elemento.innerHTML = ''

				let reader = new FileReader();
				reader.readAsDataURL(pdfBlob); 
				reader.onloadend = function() {
					let base64data = reader.result;   
					let base64dataString = base64data.toString();
					let a = document.getElementById("report");
					let b=document.createElement('iframe');
					b.src=`${base64dataString}`;
					b.width='80%'
					b.height='600';
					a.appendChild(b);
				}
			},
		},
		watch: {

        }	
	}
</script>

<style >

</style>